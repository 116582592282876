import { Address } from './types'

const capitalize = (str: string) => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()

function getFlatDescription(address: Address) {
    if (!address.flat || !address.floor) {
        return ''
    }

    return address.floorType + address.floor?.toString().padStart(2, '0') + address.flat.toString().padStart(2, '0')
}

export function formatAddress(address: Address) {
    return [capitalize(address.street), formatStreetNo(address), getFlatDescription(address)].join(' ').trim()
}

function formatStreetNo(address: Address) {
    return `${address.streetNumber}${address.entrance ? address.entrance : ''}`
}
