import Card from '../../common/components/Card'
import { wmsApi } from '../store/wmsApi'
import { CCol, CRow, CSmartTable } from '@coreui/react-pro'
import { AcknowledgeWmsSyncEventButton } from './AcknowledgeWmsSyncEventButton'
import { ErrorResponse } from '../../common/types'

export const EventManager = () => {
    const { data, status, isError, error } = wmsApi.useGetNextSyncEventQuery()

    if ((error as ErrorResponse)?.status === 404) {
        return (
            <Card
                style={{
                    // Drop shadow
                    boxShadow: '0 4px 6px rgba(0,0,0,0.2)',
                    padding: '1rem',
                }}
            >
                <h2>Bitlog Event Manager</h2>
                <p>No unprocessed events</p>
            </Card>
        )
    }

    if (isError) {
        return (
            <Card
                style={{
                    // Drop shadow
                    boxShadow: '0 4px 6px rgba(0,0,0,0.2)',
                    padding: '1rem',
                    backgroundColor: '#f8d7da',
                }}
            >
                <h3>Error fetching events from WMS: {status}</h3>
                {JSON.stringify(error)}
            </Card>
        )
    }

    return (
        <Card
            style={{
                // Drop shadow
                boxShadow: '0 4px 6px rgba(0,0,0,0.2)',
                padding: '1rem',
            }}
        >
            <h2>Bitlog Event Manager</h2>
            {data ? (
                <>
                    <div
                        style={{
                            display: 'flex',
                        }}
                    >
                        <h3>Next event</h3>
                        <div style={{ flex: 1 }}> </div>
                    </div>
                    <Card style={{ border: '1px solid #ccc', padding: '1rem' }}>
                        <CRow>
                            <CCol>
                                <h4>{data.id}</h4>
                            </CCol>
                            <CCol>
                                <p>{data.type}</p>
                            </CCol>
                            <CCol></CCol>
                        </CRow>
                        <CSmartTable
                            items={data.lines}
                            columns={[
                                { key: 'sku', label: 'SKU' },
                                { key: 'quantity', label: 'Quantity' },
                            ]}
                        />
                        <CRow>
                            <CCol style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <AcknowledgeWmsSyncEventButton syncRecordId={data.id} />
                            </CCol>
                        </CRow>
                    </Card>
                </>
            ) : (
                <p>No events</p>
            )}
        </Card>
    )
}
