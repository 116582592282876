import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { getAuthorizedBaseQuery } from '../../common/utils/apiUtils'
import { PriceHistory, RegisteredProduct, SalesHistory } from '../types'

export const productApi = createApi({
    reducerPath: 'product',
    baseQuery: getAuthorizedBaseQuery(),
    tagTypes: ['ProductRegistryListPage', 'ProductCatalog'],
    endpoints: (builder) => ({
        getProducts: builder.query<RegisteredProduct[], void>({
            query: () => ({
                url: '/admin/product-registry',
                method: 'GET',
            }),
            providesTags: ['ProductRegistryListPage'],
        }),
        getPriceHistory: builder.query<PriceHistory, { productId: string }>({
            query: ({ productId }) => ({
                url: `/admin/product-registry/${productId}/price-history`,
                method: 'GET',
            }),
        }),
        getSalesHistory: builder.query<SalesHistory, { productId: string }>({
            query: ({ productId }) => ({
                url: `/admin/product-registry/${productId}/sales-history`,
                method: 'GET',
            }),
        }),
        publishToRegion: builder.mutation<void, { regionId: number; productId: string }>({
            query: ({ regionId, productId }) => ({
                url: `/admin/product-registry/${productId}/publish`,
                method: 'POST',
                body: {
                    regionId,
                },
            }),
            invalidatesTags: ['ProductRegistryListPage'],
        }),
        updateProduct: builder.mutation<void, { productId: string; data: Partial<RegisteredProduct> }>({
            query: ({ productId, data }) => ({
                url: `/admin/product-registry/${productId}`,
                method: 'PUT',
                body: data,
            }),
            invalidatesTags: ['ProductRegistryListPage'],
        }),
        updateProductBundle: builder.mutation<void, { productId: string; data: Partial<RegisteredProduct> }>({
            query: ({ productId, data }) => ({
                url: `/admin/product-registry/${productId}/bundle`,
                method: 'PUT',
                body: data,
            }),
            invalidatesTags: ['ProductRegistryListPage'],
        }),
        activateProduct: builder.mutation<void, { productId: string }>({
            query: ({ productId }) => ({
                url: `/admin/product-registry/${productId}/activate`,
                method: 'POST',
            }),
            invalidatesTags: ['ProductRegistryListPage'],
        }),
        deactivateProduct: builder.mutation<void, { productId: string }>({
            query: ({ productId }) => ({
                url: `/admin/product-registry/${productId}/deactivate`,
                method: 'POST',
            }),
            invalidatesTags: ['ProductRegistryListPage'],
        }),
        deleteProduct: builder.mutation<void, { productId: string }>({
            query: ({ productId }) => ({
                url: `/admin/product-registry/${productId}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['ProductRegistryListPage'],
        }),
        refreshProduct: builder.mutation<void, { productId: string }>({
            query: ({ productId }) => ({
                url: `/admin/product-registry/${productId}/refresh`,
                method: 'POST',
            }),
            invalidatesTags: ['ProductRegistryListPage'],
        }),
        unpublishFromRegion: builder.mutation<void, { regionId: number; productId: string }>({
            query: ({ regionId, productId }) => ({
                url: `/admin/product-registry/${productId}/unpublish`,
                method: 'POST',
                body: { regionId },
            }),
            invalidatesTags: ['ProductRegistryListPage'],
        }),
        syncProducts: builder.mutation<void, void>({
            query: () => ({
                url: `/admin/product-registry/sync`,
                method: 'POST',
                body: {},
            }),
            invalidatesTags: ['ProductRegistryListPage'],
        }),
        exportProduct: builder.mutation<void, { productId: string }>({
            query: ({ productId }) => ({
                url: `/admin/product-registry/${productId}/wms/export`,
                method: 'POST',
            }),
        }),
        exportProducts: builder.mutation<void, void>({
            query: () => ({
                url: `/admin/product-registry/wms/export`,
                method: 'POST',
            }),
        }),
        setSku: builder.mutation<void, { productId: string; sku: string }>({
            query: ({ productId, sku }) => ({
                url: `/admin/product-registry/${productId}/sku`,
                method: 'POST',
                body: { sku: sku },
            }),
        }),
        setVisibility: builder.mutation<void, { productId: string; visible: boolean }>({
            query: ({ productId, visible }) => ({
                url: `/admin/product-registry/${productId}/visibility`,
                method: 'POST',
                body: { visibility: visible },
            }),
        }),
        setB2CProductFlag: builder.mutation<void, { productId: string; b2cEnabled: boolean }>({
            query: ({ productId, b2cEnabled }) => ({
                url: `/admin/product-registry/${productId}/b2cEnabledProduct`,
                method: 'POST',
                body: { b2cEnabled },
            }),
            invalidatesTags: ['ProductRegistryListPage'],
        }),
        setB2BProductFlag: builder.mutation<void, { productId: string; b2bEnabled: boolean }>({
            query: ({ productId, b2bEnabled }) => ({
                url: `/admin/product-registry/${productId}/b2bEnabledProduct`,
                method: 'POST',
                body: { b2bEnabled },
            }),
            invalidatesTags: ['ProductRegistryListPage'],
        }),
        setPackInBox: builder.mutation<void, { productId: string; packInBox: boolean }>({
            query: ({ productId, packInBox }) => ({
                url: `/admin/product-registry/${productId}/pack-in-box`,
                method: 'POST',
                body: { packInBox },
            }),
        }),
        generateSku: builder.query<{ sku: string }, { productId: string }>({
            query: ({ productId }) => ({
                url: `/admin/product-registry/${productId}/sku/generate`,
                method: 'GET',
            }),
        }),
    }),
})

export const {
    useGetProductsQuery,
    useSyncProductsMutation,
    usePublishToRegionMutation,
    useUnpublishFromRegionMutation,
    useActivateProductMutation,
    useDeactivateProductMutation,
    useDeleteProductMutation,
    useRefreshProductMutation,
    useUpdateProductMutation,
    useUpdateProductBundleMutation,
    useGetPriceHistoryQuery,
    useGetSalesHistoryQuery,
    useExportProductMutation,
    useExportProductsMutation,
    useSetSkuMutation,
    useGenerateSkuQuery,
    useSetVisibilityMutation,
    useSetPackInBoxMutation,
    useSetB2BProductFlagMutation,
    useSetB2CProductFlagMutation,
} = productApi
