import { useState } from 'react'

export const useVisibleColumns = (key: string) => {
    const [visibleColumns, setVisibleColumns] = useState(JSON.parse(localStorage.getItem(key) ?? '[]'))

    const handleSetVisibleColumns = (columns: string[]) => {
        setVisibleColumns(columns)
        localStorage.setItem('productTableColumns', JSON.stringify(columns))
    }

    return [visibleColumns, handleSetVisibleColumns] as const
}
