import { CCardBody, CFormSelect } from '@coreui/react-pro'
import { Customer } from '../types'
import { useDispatch, useSelector } from 'react-redux'
import { resetSetB2BCompanySettings, setB2BCompanySettings, SetB2BCompanySettingsProps } from '../store/customerSlice'
import { useEffect, useState } from 'react'
import Card from '../../common/components/Card'
import { cilPencil, cilX } from '@coreui/icons'
import CardHeader from '../../common/components/CardHeader'
import { useGetCompaniesQuery } from '../../company/store/companyApi'
import { RegisteredCompany } from '../../company/types'
import { IRootState } from '../../store'
import { Status } from '../../common/types'
import { addToast } from '../../common/store/commonSlice'

interface B2BCustomerCardProps {
    customer: Customer
}

export default function B2BCustomerCard({ customer }: B2BCustomerCardProps) {
    const dispatch = useDispatch()
    const [isEditing, setIsEditing] = useState<boolean>(false)
    const { data: companies } = useGetCompaniesQuery()
    const { setB2BCompanySettingsStatus, setB2BCompanySettingsError } = useSelector((state: IRootState) => ({
        setB2BCompanySettingsStatus: state.customer.setB2BCompanySettingsStatus,
        setB2BCompanySettingsError: state.customer.setB2BCompanySettingsError,
    }))

    let currentCompany: RegisteredCompany | null = null
    if (companies && customer.companyId) {
        const foundCompany = companies.find((c) => c.companyId == customer.companyId)
        if (foundCompany) {
            currentCompany = foundCompany
        }
    }

    // Local state for <CFormSelect> value
    // - If the user is already associated with a company, use that ID; else empty
    const [selectedCompanyId, setSelectedCompanyId] = useState(currentCompany?.companyId ?? '')

    const handleCardActionClick = () => {
        setIsEditing(!isEditing)
    }

    const actionIcon = isEditing ? cilX : cilPencil

    const handleSetCustomerCompanyId = async (companyId: string) => {
        const setB2BCompanySettingsProps: SetB2BCompanySettingsProps = {
            customerId: String(customer.customerId),
            companyId: companyId.length == 0 ? null : companyId,
        }
        dispatch(setB2BCompanySettings(setB2BCompanySettingsProps))
    }

    useEffect(() => {
        if (setB2BCompanySettingsStatus === Status.Failed) {
            dispatch(
                addToast({
                    message: `Error when associating Customer with given company: ${setB2BCompanySettingsError || ''}`,
                    color: 'danger',
                })
            )
            // Reset state so the user sees the placeholder option:
            setSelectedCompanyId('')
            // Reset the Redux slice to Idle
            dispatch(resetSetB2BCompanySettings())
        } else if (setB2BCompanySettingsStatus === Status.Succeeded) {
            if (currentCompany) {
                dispatch(
                    addToast({
                        message: `Successfully assosciated Customer with Company ${currentCompany?.companyName}`,
                        color: 'success',
                    })
                )
            } else {
                dispatch(
                    addToast({
                        message: `Successfully disassociated Customer from B2B company`,
                        color: 'success',
                    })
                )
            }

            // Possibly show success toast or do other UI updates
            dispatch(resetSetB2BCompanySettings())
            setIsEditing(false)
        }
    }, [setB2BCompanySettingsStatus, setB2BCompanySettingsError, dispatch])

    useEffect(() => {
        setSelectedCompanyId(currentCompany?.companyId ?? '')
    }, [currentCompany])

    return (
        <Card onAction={handleCardActionClick} actionIcon={actionIcon}>
            <CardHeader>Customer B2B settings</CardHeader>
            <CCardBody>
                {!companies || companies.length === 0 ? (
                    <div>No B2B companies registered in the system.</div>
                ) : (
                    <CFormSelect
                        disabled={!isEditing}
                        id="b2bCompany"
                        value={selectedCompanyId}
                        onChange={(e) => {
                            handleSetCustomerCompanyId(e.currentTarget.value)
                        }}
                    >
                        <option value="">-- Select a company --</option>
                        {companies.map((company) => (
                            <option
                                key={company.companyId}
                                value={company.companyId}
                                label={`${company.companyName} ${company.companyOrganisationNumber}`}
                            />
                        ))}
                    </CFormSelect>
                )}
            </CCardBody>
        </Card>
    )
}
