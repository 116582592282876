import { Address } from '../../address/types'
import { CCardBody, CSmartTable } from '@coreui/react-pro'
import { useState } from 'react'
import { formatAddress } from '../../address/addressFormattingUtils'
import Card from '../../common/components/Card'
import CardHeader from '../../common/components/CardHeader'
import CIcon from '@coreui/icons-react'
import { cilEnvelopeLetter } from '@coreui/icons'

export interface CompanyAddressTableProps {
    addresses: Address[]
    onSelectChange?: (ids: string[]) => void
}

interface CompanyAddressTableItem {
    firstName: string
    lastName: string
    addressLine: string
}

export default function CompanyAddressTable({ addresses, onSelectChange }: CompanyAddressTableProps) {
    const items = addresses
        // There are different types of addresses in the backend but in this table we specifically want to only display
        // addresses meant to be used for deliveries.
        .filter((address) => address.type == 'D')
        .map(
            (address) =>
                ({
                    firstName: address.firstName,
                    lastName: address.lastName,
                    addressLine: formatAddress(address),
                } as CompanyAddressTableItem)
        )
    const [itemsShown, setItemsShown] = useState(items.length)

    return (
        <Card>
            <CardHeader>
                <CIcon icon={cilEnvelopeLetter} size={'xl'} className={'mr-1'} />
                Company delivery addresses
            </CardHeader>
            <CCardBody>
                <div>
                    Displaying {itemsShown} out of {items.length} addresses
                </div>
                <CSmartTable
                    selectable={onSelectChange !== undefined}
                    clickableRows
                    onFilteredItemsChange={(items) => setItemsShown(items.length)}
                    onSelectedItemsChange={(items) => {
                        onSelectChange && onSelectChange(items.map((item) => item.id as string))
                    }}
                    tableProps={{
                        responsive: true,
                        striped: true,
                        hover: true,
                        borderless: true,
                    }}
                    columns={[
                        {
                            key: 'firstName',
                            label: 'First name',
                            _style: { width: '225%' },
                        },
                        {
                            key: 'lastName',
                            label: 'Last name',
                            _style: { width: '25%' },
                        },
                        {
                            key: 'addressLine',
                            label: 'Address line',
                            _style: { width: '50%' },
                        },
                    ]}
                    itemsPerPage={100000}
                    items={items}
                />
            </CCardBody>
        </Card>
    )
}
