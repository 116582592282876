import { Redirect, Route, Switch, useRouteMatch } from 'react-router'
import DistributionCalendarPage from './DistributionCalendarPage'
import { DistributionCompaniesPage } from './DistributionCompaniesPage/DistributionCompaniesPage'
import ShipmentPage from './ShipmentPage'

function DistributionPage() {
    const { path } = useRouteMatch()
    return (
        <div>
            <Switch>
                <Redirect exact from={path} to={`${path}/kalender`} />
                <Route exact path={`${path}/kalender`}>
                    <DistributionCalendarPage />
                </Route>
                <Route path={`${path}/selskaper`}>
                    <DistributionCompaniesPage />
                </Route>
                <Route path={`${path}/sendinger`}>
                    <ShipmentPage />
                </Route>
            </Switch>
        </div>
    )
}

export default DistributionPage
