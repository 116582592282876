import { RegisteredProduct } from '../types'
import Card from '../../common/components/Card'
import { CCardBody } from '@coreui/react-pro'
import CardHeader from '../../common/components/CardHeader'
import React from 'react'
import { CFormSwitch } from '@coreui/react-pro'
import { useSetB2BProductFlagMutation, useSetB2CProductFlagMutation } from '../store/productApi'

interface B2BB2CProductSettingsCard {
    product: RegisteredProduct
}
export default function B2BB2CProductSettingsCard({ product }: B2BB2CProductSettingsCard) {
    const [setB2BProductFlag] = useSetB2BProductFlagMutation()
    const [setB2CProductFlag] = useSetB2CProductFlagMutation()

    return (
        <Card>
            <CardHeader>B2B and B2C product settings</CardHeader>
            <CCardBody>
                <p style={{ color: 'var(--cui-gray)' }}>
                    Choose if given product should be visible for B2C and/or B2B clients.
                </p>
                <CFormSwitch
                    key="b2b"
                    style={
                        {
                            '--cui-form-check-input-checked-bg-color': 'var(--cui-success)',
                            '--cui-form-check-input-checked-border-color': 'var(--cui-success)',
                        } as React.CSSProperties
                    }
                    color={'success'}
                    size={'xl'}
                    className={'mb-3'}
                    label="B2B enabled"
                    checked={product.b2bEnabled}
                    onChange={(e) => setB2BProductFlag({ productId: product.id, b2bEnabled: e.target.checked })}
                />
                <CFormSwitch
                    key="b2c"
                    style={
                        {
                            '--cui-form-check-input-checked-bg-color': 'var(--cui-success)',
                            '--cui-form-check-input-checked-border-color': 'var(--cui-success)',
                        } as React.CSSProperties
                    }
                    color={'success'}
                    size={'xl'}
                    className={'mb-3'}
                    label="B2C enabled"
                    checked={product.b2cEnabled}
                    onChange={(e) => setB2CProductFlag({ productId: product.id, b2cEnabled: e.target.checked })}
                />
            </CCardBody>
        </Card>
    )
}
