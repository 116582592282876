import { FormEvent, useEffect, useState } from 'react'
import { CButton, CForm, CModal, CModalBody, CModalHeader, CModalTitle } from '@coreui/react-pro'
import { Input } from '../../common/components/Input/Input'
import { CreateCompanyRequest } from '../types'
import { useCreateCompanyMutation } from '../store/companyApi'
import { addToast } from '../../common/store/commonSlice'
import { useDispatch } from 'react-redux'

interface CreateNewCompanyModallProps {
    visible: boolean
    onClose: () => void
}

export default function CreateNewCompanyModal({ visible, onClose }: CreateNewCompanyModallProps) {
    const dispatch = useDispatch()
    const [createCompanyMutation, createCompanyMutationResult] = useCreateCompanyMutation()
    const [isEditing, setIsEditing] = useState<boolean>(true)

    const [companyName, setCompanyName] = useState('')
    const [companyPhoneNumber, setCompanyPhoneNumber] = useState('')
    const [organisationNumber, setOrganisationNumber] = useState('')
    const [invoiceAddress, setInvoiceAddress] = useState('')
    const [city, setCity] = useState('')
    const [zipCode, setZipCode] = useState('')

    const isCompanyNameValid = companyName.trim().length > 0
    // Regex copied from morgenlevering-frontend src/utilities/validators.ts since it's used to validate phone numbers before the order can be submitted
    const isCompanyPhoneValid = /^((\+47|0047)?\s?([49]( *\d *){7}))$/.test(companyPhoneNumber)
    const isOrganisationNumberValid = organisationNumber.length === 9 && /^\d+$/.test(organisationNumber)
    const isInvoiceAddressValid = invoiceAddress.trim().length > 0
    const isCityValid = city.trim().length > 0
    const isZipCodeValid = zipCode.trim().length == 4 && /^\d+$/.test(zipCode)
    const isFormValid =
        isCompanyNameValid &&
        isCompanyPhoneValid &&
        isOrganisationNumberValid &&
        isInvoiceAddressValid &&
        isCityValid &&
        isZipCodeValid

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        const request: CreateCompanyRequest = {
            companyName: companyName,
            companyPhoneNumber: companyPhoneNumber,
            companyOrganisationNumber: organisationNumber,
            companyInvoiceAddress: {
                address: invoiceAddress,
                city: city,
                zipCode: zipCode,
            },
        }
        createCompanyMutation(request)
        setIsEditing(false)
    }

    useEffect(() => {
        setIsEditing(true)
        if (createCompanyMutationResult.isSuccess) {
            dispatch(
                addToast({
                    message: 'Company successfully registered',
                    color: 'success',
                })
            )
            onClose()
        }
        if (createCompanyMutationResult.isError) {
            let errorMessage = ''
            if ('data' in createCompanyMutationResult.error && 'message' in createCompanyMutationResult.error.data) {
                errorMessage = createCompanyMutationResult.error.data.message
            }
            dispatch(
                addToast({
                    message: 'Error when adding company: ' + errorMessage,
                    color: 'danger',
                })
            )
        }
    }, [createCompanyMutationResult])

    return (
        <CModal visible={visible} onClose={onClose} title="Register new company">
            <CModalHeader closeButton={true}>
                <CModalTitle id="LiveDemoExampleLabel">Create new company</CModalTitle>
            </CModalHeader>
            <CModalBody>
                <CForm onSubmit={handleSubmit}>
                    <div className={'mb-3'}>
                        <Input
                            disabled={!isEditing}
                            type="text"
                            label="Company name"
                            text="Company name, visible on the final invoice."
                            value={companyName}
                            onChange={(e) => setCompanyName(e.target.value)}
                            valid={isCompanyNameValid}
                            invalid={!isCompanyNameValid}
                        />
                    </div>
                    <div className={'mb-3'}>
                        <Input
                            disabled={!isEditing}
                            type="text"
                            label="Company phone number"
                            text="Company phone number, visible on the final invoice."
                            value={companyPhoneNumber}
                            onChange={(e) => setCompanyPhoneNumber(e.target.value)}
                            valid={isCompanyPhoneValid}
                            invalid={!isCompanyPhoneValid}
                        />
                    </div>
                    <div className={'mb-3'}>
                        <Input
                            disabled={!isEditing}
                            type="text"
                            label="Company organisation number"
                            text="9 digit company organisation number."
                            value={organisationNumber}
                            onChange={(e) => setOrganisationNumber(e.target.value)}
                            valid={isOrganisationNumberValid}
                            invalid={!isOrganisationNumberValid}
                        />
                    </div>
                    <div className={'mb-3'}>
                        <Input
                            disabled={!isEditing}
                            type="text"
                            label="Address line"
                            text="Full addres line without city and zip code, visible on the final invoice."
                            value={invoiceAddress}
                            onChange={(e) => setInvoiceAddress(e.target.value)}
                            valid={isInvoiceAddressValid}
                            invalid={!isInvoiceAddressValid}
                        />
                    </div>
                    <div className={'mb-3'}>
                        <Input
                            disabled={!isEditing}
                            type="text"
                            label="City"
                            text="City, visible on the final invoice."
                            value={city}
                            onChange={(e) => setCity(e.target.value)}
                            valid={isCityValid}
                            invalid={!isCityValid}
                        />
                    </div>
                    <div className={'mb-3'}>
                        <Input
                            disabled={!isEditing}
                            type="text"
                            label="Zip code"
                            text="Zip code, visible on the final invoice."
                            value={zipCode}
                            onChange={(e) => setZipCode(e.target.value)}
                            valid={isZipCodeValid}
                            invalid={!isZipCodeValid}
                        />
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <CButton type="submit" color="primary" disabled={!isFormValid && isEditing}>
                            {'Create company'}
                        </CButton>
                    </div>
                </CForm>
            </CModalBody>
        </CModal>
    )
}
