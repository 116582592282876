import { FormEvent, useEffect, useState } from 'react'
import { RegisteredCompany, UpdateCompanyRequest } from '../types'
import Card from '../../common/components/Card'
import { cilDescription, cilPencil, cilX } from '@coreui/icons'
import CardHeader from '../../common/components/CardHeader'
import CIcon from '@coreui/icons-react'
import { CButton, CCardBody, CForm } from '@coreui/react-pro'
import { Input } from '../../common/components/Input/Input'
import { useUpdateCompanyMutation } from '../store/companyApi'
import { addToast } from '../../common/store/commonSlice'
import { useDispatch } from 'react-redux'

export interface CompanyInfoCardProps {
    company: RegisteredCompany
}

export default function CompanyInfoCard({ company }: CompanyInfoCardProps) {
    const dispatch = useDispatch()
    const [isEditing, setIsEditing] = useState<boolean>(false)
    const [updateCompanyMutation, updateCompanyMutationResult] = useUpdateCompanyMutation()

    const [companyName, setCompanyName] = useState(company.companyName)
    const [companyPhoneNumber, setCompanyPhoneNumber] = useState(company.companyPhoneNumber)
    const [organisationNumber] = useState(company.companyOrganisationNumber)
    const [invoiceAddress, setInvoiceAddress] = useState(company.companyInvoiceAddress.address)
    const [city, setCity] = useState(company.companyInvoiceAddress.city)
    const [zipCode, setZipCode] = useState(company.companyInvoiceAddress.zipCode)

    const isCompanyNameValid = companyName.trim().length > 0
    // Regex copied from morgenlevering-frontend src/utilities/validators.ts since it's used to validate phone numbers before the order can be submitted
    const isCompanyPhoneValid = /^((\+47|0047)?\s?([49]( *\d *){7}))$/.test(companyPhoneNumber)
    const isInvoiceAddressValid = invoiceAddress.trim().length > 0
    const isCityValid = city.trim().length > 0
    const isZipCodeValid = zipCode.trim().length == 4 && /^\d+$/.test(zipCode)
    const isFormValid =
        isCompanyNameValid && isCompanyPhoneValid && isInvoiceAddressValid && isCityValid && isZipCodeValid

    const handleCardActionClick = () => {
        setIsEditing(!isEditing)
    }

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        const request: UpdateCompanyRequest = {
            companyName: companyName,
            companyPhoneNumber: companyPhoneNumber,
            companyInvoiceAddress: {
                address: invoiceAddress,
                city: city,
                zipCode: zipCode,
            },
        }
        updateCompanyMutation({ companyId: company.companyId, body: request })
        setIsEditing(false)
    }

    useEffect(() => {
        if (updateCompanyMutationResult.isSuccess) {
            dispatch(
                addToast({
                    message: 'Company successfully updated',
                    color: 'success',
                })
            )
        }
        if (updateCompanyMutationResult.isError) {
            let errorMessage = ''
            if ('data' in updateCompanyMutationResult.error && 'message' in updateCompanyMutationResult.error.data) {
                errorMessage = updateCompanyMutationResult.error.data.message
            }
            dispatch(
                addToast({
                    message: 'Error when updating company: ' + errorMessage,
                    color: 'danger',
                })
            )
        }
    }, [updateCompanyMutationResult])

    return (
        <Card onAction={handleCardActionClick} actionIcon={isEditing ? cilX : cilPencil}>
            <CardHeader>
                <CIcon icon={cilDescription} size={'xl'} className={'mr-1'} />
                Company info
            </CardHeader>
            <CCardBody>
                <CForm onSubmit={handleSubmit}>
                    <div className={'mb-3'}>
                        <Input
                            disabled={true}
                            type="text"
                            label="Company organisation number"
                            text="9 digit company organisation number."
                            value={organisationNumber}
                        />
                    </div>
                    <div className={'mb-3'}>
                        <Input
                            disabled={!isEditing}
                            type="text"
                            label="Company name"
                            text="Company name, visible on the final invoice."
                            value={companyName}
                            onChange={(e) => setCompanyName(e.target.value)}
                            valid={isCompanyNameValid}
                            invalid={!isCompanyNameValid}
                        />
                    </div>
                    <div className={'mb-3'}>
                        <Input
                            disabled={!isEditing}
                            type="text"
                            label="Company phone number"
                            text="Company phone number, visible on the final invoice."
                            value={companyPhoneNumber}
                            onChange={(e) => setCompanyPhoneNumber(e.target.value)}
                            valid={isCompanyPhoneValid}
                            invalid={!isCompanyPhoneValid}
                        />
                    </div>
                    <div className={'mb-3'}>
                        <Input
                            disabled={!isEditing}
                            type="text"
                            label="Address line"
                            text="Full addres line without city and zip code, visible on the final invoice."
                            value={invoiceAddress}
                            onChange={(e) => setInvoiceAddress(e.target.value)}
                            valid={isInvoiceAddressValid}
                            invalid={!isInvoiceAddressValid}
                        />
                    </div>
                    <div className={'mb-3'}>
                        <Input
                            disabled={!isEditing}
                            type="text"
                            label="City"
                            text="City, visible on the final invoice."
                            value={city}
                            onChange={(e) => setCity(e.target.value)}
                            valid={isCityValid}
                            invalid={!isCityValid}
                        />
                    </div>
                    <div className={'mb-3'}>
                        <Input
                            disabled={!isEditing}
                            type="text"
                            label="Zip code"
                            text="Zip code, visible on the final invoice."
                            value={zipCode}
                            onChange={(e) => setZipCode(e.target.value)}
                            valid={isZipCodeValid}
                            invalid={!isZipCodeValid}
                        />
                    </div>
                    {isEditing && (
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <CButton type="submit" color="primary" disabled={!isFormValid}>
                                {'Lagre'}
                            </CButton>
                        </div>
                    )}
                </CForm>
            </CCardBody>
        </Card>
    )
}
