import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { getAuthorizedBaseQuery } from '../../common/utils/apiUtils'
import { CreateCompanyRequest, RegisteredCompany, UpdateCompanyRequest } from '../types'

export const companyApi = createApi({
    reducerPath: 'company',
    baseQuery: getAuthorizedBaseQuery(),
    tagTypes: ['CompanyRegistryListPage'],
    endpoints: (builder) => ({
        getCompanies: builder.query<RegisteredCompany[], void>({
            query: () => ({
                url: '/admin/company',
                method: 'GET',
            }),
            providesTags: ['CompanyRegistryListPage'],
        }),
        createCompany: builder.mutation<void, CreateCompanyRequest>({
            query: (body: CreateCompanyRequest) => ({
                url: `/admin/company`,
                method: 'POST',
                body: body,
            }),
            invalidatesTags: ['CompanyRegistryListPage'],
        }),
        updateCompany: builder.mutation<void, { companyId: string; body: UpdateCompanyRequest }>({
            query: (props) => ({
                url: `/admin/company/` + props.companyId,
                method: 'PUT',
                body: props.body,
            }),
            invalidatesTags: ['CompanyRegistryListPage'],
        }),
    }),
})

export const { useGetCompaniesQuery, useCreateCompanyMutation, useUpdateCompanyMutation } = companyApi
