import { CCard, CCardBody } from '@coreui/react-pro'
import moment from 'moment'
import { craftUrl } from '../../../config'
import { formatAddress } from '../../../address/addressFormattingUtils'
import { getFriendlyOrderStatus } from '../../orderstatus'
import { HistoricOrderDelivery } from '../../types'

import style from './Delivery.module.scss'

interface DeliveryProps {
    delivery: HistoricOrderDelivery
}

export default function Delivery({ delivery }: DeliveryProps) {
    const CraftOrderId = ({ id }: { id: number }) => (
        <a
            href={`${craftUrl}/admin/shopping/orders/${id}`}
            target="_blank"
            className={style.craftLink}
            rel="noreferrer"
        >
            {id}
        </a>
    )

    const deliveryTotalPrice =
        delivery.freightPrice +
        delivery.products
            .map((product) => (product.netAmount + product.vatAmount) * product.quantity)
            .reduce((a, b) => a + b)

    return (
        <CCard className={style.card}>
            <CCardBody>
                <table>
                    <tbody>
                        <tr key="orderId">
                            <th>Craft ordrenummer</th>
                            <td>
                                <CraftOrderId id={delivery.externalOrderId} />
                            </td>
                        </tr>
                        <tr>
                            <th>Intern ordrestatus</th>
                            <td>{delivery.singleOrderStatus}</td>
                        </tr>
                        <tr>
                            <th>Ordrestatus</th>
                            <td>{getFriendlyOrderStatus(delivery.orderStatus)}</td>
                        </tr>
                        <tr>
                            <th>Bookingtidspunkt</th>
                            <td>
                                {delivery.bookingTime
                                    ? moment(delivery.bookingTime).format('Do MMMM YYYY [kl.] HH:mm')
                                    : 'Ikke satt'}
                            </td>
                        </tr>
                        <tr className={style.thBold}>
                            <th>Produkter</th>
                        </tr>
                        {delivery.products.map((product) => (
                            <tr key={product.productExternalId}>
                                <th className={style.tableHeader}>
                                    <span>{`${product.quantity} \u00D7 `}</span>
                                    <span className={style.italic}>{`${product.title}`}</span>
                                </th>
                                <td>{`${(product.netAmount + product.vatAmount).toFixed(2)} NOK`}</td>
                            </tr>
                        ))}
                        <tr className={style.thSpacing}>
                            <th>Frakt</th>
                            <td>{`${delivery.freightPrice.toFixed(2)} NOK`}</td>
                        </tr>
                        <tr className={style.thSpacing}>
                            <th>Sum levering</th>
                            <td>{`${deliveryTotalPrice.toFixed(2)} NOK`}</td>
                        </tr>
                        <tr className={style.thBold}>
                            <th>Mottaker</th>
                        </tr>
                        <tr>{`${delivery.address.firstName} ${delivery.address.lastName}`}</tr>
                        <tr className={style.thSmallSpacing}>
                            <th>{formatAddress(delivery.address)}</th>
                        </tr>
                        <tr>{`${delivery.address.zipCode}, ${delivery.address.zipName}`}</tr>
                        {delivery.address.careOf && (
                            <tr className={style.thBigSpacing}>
                                <th>Informasjon til budet</th>
                                <td>{`${delivery.address.careOf}`}</td>
                            </tr>
                        )}
                        <tr className={delivery.address.careOf ? '' : style.thBigSpacing}>
                            <th>Gavemelding</th>
                            <td>{delivery.giftMessage}</td>
                        </tr>
                        <tr>
                            <th>Leveringsdag</th>
                            <td>{moment(delivery.deliveryDate).format('Do MMMM YYYY')}</td>
                        </tr>
                    </tbody>
                </table>
            </CCardBody>
        </CCard>
    )
}
