import { Page } from '../../common'
import { CButton, CCol, CRow, CSpinner } from '@coreui/react-pro'
import { useGetCompaniesQuery } from '../store/companyApi'
import ErrorMessage from '../../common/components/ErrorMessage/ErrorMessage'
import CompanyTable from '../components/CompanyTable'
import { useState } from 'react'
import CreateNewCompanyModal from '../components/CreateNewCompanyModal'
import CIcon from '@coreui/icons-react'
import { cilPlus } from '@coreui/icons/js/free'

export default function CompanyRegistryListPage() {
    const { data: companies, isSuccess, isLoading, isError, error } = useGetCompaniesQuery()
    const [createNewCompanyModalVisible, setCreateNewCompanyModalVisible] = useState(false)
    return (
        <Page
            title={'Company registry'}
            preamble={'Here you will find an overview of all Companies registered in the system'}
        >
            <CreateNewCompanyModal
                visible={createNewCompanyModalVisible}
                onClose={() => {
                    setCreateNewCompanyModalVisible(false)
                }}
            />
            <CRow>
                <CCol
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                    }}
                >
                    <CButton
                        onClick={() => {
                            setCreateNewCompanyModalVisible(true)
                        }}
                        color={'success'}
                    >
                        <CIcon className={'mr-1'} icon={cilPlus} size="sm" />
                        Register new company
                    </CButton>
                </CCol>
            </CRow>
            {isLoading && <CSpinner />}
            {isError && <ErrorMessage error={error} />}
            {isSuccess && <CompanyTable companies={companies} />}
        </Page>
    )
}
