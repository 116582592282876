import { applyMiddleware, combineReducers, createStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunk from 'redux-thunk'
import { adminUserApi } from './adminuser/store/adminUserApi'
import commonReducer, { CommonState } from './common/store/commonSlice'
import customerReducer, { CustomerState } from './customer/store/customerSlice'
import orderHistoryReducer, { OrderHistoryState } from './customer/store/orderHistorySlice'
import paymentMethodReducer, { PaymentMethodState } from './customer/store/paymentMethodSlice'
import { subscriptionApi } from './customer/store/subscriptionApi'
import { distributionApi } from './distribution/store/distributionApi'
import distributionCalendarReducer, { DistributionCalendarState } from './distribution/store/distributionCalendarSlice'
import { freightApi } from './freight/store/freightApi'
import { orderAdminApi } from './order/store/orderAdminApi'
import { paymentAdminApi } from './order/store/paymentAdminApi'
import { orderMonitoringApi } from './ordermonitoring/store/orderMonitoringApi'
import { regionApi } from './region/store/regionApi'
import { notificationAdminApi } from './settings/store/notificationAdminApi'
import { orderSearchSettingsApi } from './settings/store/orderSearchSettingsApi'
import { productApi } from './product/store/productApi'
import { supplierApi } from './product/store/supplierApi'
import { adminRoleApi } from './adminuser/store/adminRoleApi'
import { authenticationApi } from './auth/authenticationApi'
import { featureApi } from './feature/store/featureApi'
import { reportsApi } from './reports/store/reportsApi'
import { inventoryApi } from './inventory/store/inventoryApi'
import { capacityApi } from './packagelocation/store/capacityApi'
import { packageLocationApi } from './packagelocation/store/packageLocationApi'
import { regionAdminApi } from './region/store/regionAdminApi'
import { purchaseOrderApi } from './purchaseorder/store/purchaseOrderApi'
import { projectionsApi } from './event/projectionsApi'
import { sortingApi } from './distribution/store/sortingApi'
import { wmsApi } from './wms/store/wmsApi'
import { companyApi } from './company/store/companyApi'
import { addressApi } from './address/store/addressApi'

export interface IRootState {
    common: CommonState
    customer: CustomerState
    distributionCalendar: DistributionCalendarState
    orderHistory: OrderHistoryState
    paymentMethod: PaymentMethodState
}

const store = createStore<any, any, any, any>(
    combineReducers({
        common: commonReducer,
        customer: customerReducer,
        distributionCalendar: distributionCalendarReducer,
        orderHistory: orderHistoryReducer,
        paymentMethod: paymentMethodReducer,
        [adminRoleApi.reducerPath]: adminRoleApi.reducer,
        [adminUserApi.reducerPath]: adminUserApi.reducer,
        [authenticationApi.reducerPath]: authenticationApi.reducer,
        [distributionApi.reducerPath]: distributionApi.reducer,
        [capacityApi.reducerPath]: capacityApi.reducer,
        [freightApi.reducerPath]: freightApi.reducer,
        [inventoryApi.reducerPath]: inventoryApi.reducer,
        [notificationAdminApi.reducerPath]: notificationAdminApi.reducer,
        [orderAdminApi.reducerPath]: orderAdminApi.reducer,
        [orderMonitoringApi.reducerPath]: orderMonitoringApi.reducer,
        [orderSearchSettingsApi.reducerPath]: orderSearchSettingsApi.reducer,
        [paymentAdminApi.reducerPath]: paymentAdminApi.reducer,
        [regionApi.reducerPath]: regionApi.reducer,
        [regionAdminApi.reducerPath]: regionAdminApi.reducer,
        [subscriptionApi.reducerPath]: subscriptionApi.reducer,
        [productApi.reducerPath]: productApi.reducer,
        [purchaseOrderApi.reducerPath]: purchaseOrderApi.reducer,
        [supplierApi.reducerPath]: supplierApi.reducer,
        [featureApi.reducerPath]: featureApi.reducer,
        [reportsApi.reducerPath]: reportsApi.reducer,
        [packageLocationApi.reducerPath]: packageLocationApi.reducer,
        [projectionsApi.reducerPath]: projectionsApi.reducer,
        [sortingApi.reducerPath]: sortingApi.reducer,
        [wmsApi.reducerPath]: wmsApi.reducer,
        [companyApi.reducerPath]: companyApi.reducer,
        [addressApi.reducerPath]: addressApi.reducer,
    }),
    composeWithDevTools(
        applyMiddleware<any>(
            thunk,
            adminRoleApi.middleware,
            adminUserApi.middleware,
            authenticationApi.middleware,
            distributionApi.middleware,
            capacityApi.middleware,
            featureApi.middleware,
            freightApi.middleware,
            inventoryApi.middleware,
            notificationAdminApi.middleware,
            orderAdminApi.middleware,
            orderMonitoringApi.middleware,
            orderSearchSettingsApi.middleware,
            paymentAdminApi.middleware,
            packageLocationApi.middleware,
            productApi.middleware,
            purchaseOrderApi.middleware,
            regionApi.middleware,
            regionAdminApi.middleware,
            subscriptionApi.middleware,
            supplierApi.middleware,
            reportsApi.middleware,
            projectionsApi.middleware,
            sortingApi.middleware,
            wmsApi.middleware,
            companyApi.middleware,
            addressApi.middleware
        )
    )
)

setupListeners(store.dispatch)

export default store
