import { Page } from '../../common'
import Toolbar from '../../common/components/Toolbar'
import CIcon from '@coreui/icons-react'
import { cilPlus } from '@coreui/icons'
import { CButton, CSmartTable } from '@coreui/react-pro'
import React, { useState } from 'react'
import CreateShipmentModal from '../components/CreateShipmentModal'
import { distributionApi, ShipmentItem, ShipmentView } from '../store/distributionApi'

export default function ShipmentPage() {
    const [showCreateShipmentModal, toggleCreateShipmentModal] = useState(false)
    const { data: shipments } = distributionApi.useGetShipmentsQuery()
    return (
        <Page title={'Sendinger'} preamble={'Oversikt over sendinger'}>
            <Toolbar>
                <CButton
                    onClick={() => {
                        toggleCreateShipmentModal(!showCreateShipmentModal)
                    }}
                >
                    <CIcon className={'mr-1'} icon={cilPlus} size="sm" />
                    Ny sending
                </CButton>
            </Toolbar>
            <CreateShipmentModal visible={showCreateShipmentModal} onClose={() => toggleCreateShipmentModal(false)} />
            <CSmartTable
                items={shipments || []}
                columns={[
                    {
                        key: 'shipmentId',
                        label: 'SendingsID',
                    },
                    {
                        key: 'deliveryDate',
                        label: 'Leveringsdato',
                    },
                    {
                        key: 'recipient',
                        label: 'Mottaker',
                    },
                ]}
                scopedColumns={{
                    recipient: (item: ShipmentView) => {
                        return (
                            <td>
                                {item.recipient.recipient}
                                <br />
                                {item.recipient.streetAddress}
                                <br />
                                {item.recipient.zipCode}
                                <br />
                                {item.recipient.postalName}
                            </td>
                        )
                    },
                }}
            />
        </Page>
    )
}
