import { Page } from '../../common'
import { EventManager } from '../components/EventManager'
import { AuthorizedContent } from '../../auth/AuthorizedContent'

export default function WmsPage() {
    return (
        <Page title={'WMS'}>
            <AuthorizedContent requiredPermissions={['wms.read']}>
                <EventManager />
            </AuthorizedContent>
        </Page>
    )
}
