import { Page } from '../../common'
import { useParams } from 'react-router'
import { CAlert, CCol, CRow, CSpinner } from '@coreui/react-pro'
import { useGetCompaniesQuery } from '../store/companyApi'
import { useGetCompanyAddressesQuery } from '../../address/store/addressApi'
import { RegisteredCompany } from '../types'
import CompanyInfoCard from '../components/CompanyInfoCard'
import CompanyAddressTable from '../components/CompanyAddressTable'

interface CompanyDetailsPageParams {
    companyId: string
}

export default function CompanyDetailsPage() {
    const { companyId } = useParams<CompanyDetailsPageParams>()
    const {
        data: companies,
        isSuccess: isSuccessGetCompanies,
        isLoading: isLoadingGetCompanies,
        isError: isErrorGetCompanies,
    } = useGetCompaniesQuery()

    const {
        data: addresses,
        isSuccess: isSuccessGetAddresses,
        isLoading: isLoadingGetAddresses,
        isError: isErrorGetAddresses,
    } = useGetCompanyAddressesQuery(companyId)

    if (isErrorGetCompanies) {
        return <CAlert color={'danger'}>Could not fetch Companies</CAlert>
    }
    if (isErrorGetAddresses) {
        return <CAlert color={'danger'}>Could not fetch Addresses for a company</CAlert>
    }
    if (isLoadingGetCompanies || isLoadingGetAddresses) {
        return <CSpinner />
    }

    const company: RegisteredCompany | undefined = companies?.find((company) => company.companyId === companyId)
    if (!company) {
        return <CAlert color={'danger'}>Could not find Company with id {companyId}</CAlert>
    }

    return (
        <Page title={company?.companyName ?? 'Company details'} preamble={company?.companyId}>
            {isSuccessGetCompanies && isSuccessGetAddresses && company && (
                <>
                    <CRow>
                        <CCol>
                            <CompanyInfoCard company={company} />
                        </CCol>
                    </CRow>
                    <CRow>
                        <CCol>
                            <CompanyAddressTable addresses={addresses} />
                        </CCol>
                    </CRow>
                </>
            )}
        </Page>
    )
}
