import { Page } from '../../common'
import { CCol, CRow, CSpinner } from '@coreui/react-pro'
import { useGetProductsQuery } from '../store/productApi'
import ErrorMessage from '../../common/components/ErrorMessage/ErrorMessage'
import ProductTable from '../components/producttable/ProductTable'

import React from 'react'
import { DownloadProductRegistryAsCsvButton } from '../components/buttons/DownloadProductRegistryAsCsvButton'
import { ExportProductsToWMSButton } from '../components/buttons/ExportProductsToWMSButton'
import { SynchronizeWithCraftButton } from '../components/buttons/SynchronizeWithCraftButton'
import { CreateNewProductButton } from '../components/buttons/CreateNewProductButton'
import { CreateWarehousePDFButton } from '../components/buttons/CreateWarehousePDFButton'

export default function ProductRegistryListPage() {
    const { data: products, isSuccess, isLoading, isError, error } = useGetProductsQuery()

    return (
        <Page
            title={'Produktregister'}
            preamble={'Her finner du oversikt over alle produkter som er registrert i systemet'}
        >
            <CRow>
                <CCol
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                    }}
                >
                    <CreateNewProductButton />
                    <SynchronizeWithCraftButton />
                    <ExportProductsToWMSButton />
                    <DownloadProductRegistryAsCsvButton />
                    <CreateWarehousePDFButton products={products || []} />
                </CCol>
            </CRow>
            {isLoading && <CSpinner />}
            {isError && <ErrorMessage error={error} />}
            {isSuccess && <ProductTable products={products} />}
        </Page>
    )
}
