import { cilExternalLink } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import { CButton } from '@coreui/react-pro'
import { FC } from 'react'
import { wmsApi } from '../store/wmsApi'
import { useDispatch } from 'react-redux'
import { executeMutation } from '../../common/utils/apiUtils'

interface AcknowledgeWmsSyncTransactionButtonProps {
    syncRecordId: string
}

export const AcknowledgeWmsSyncEventButton: FC<AcknowledgeWmsSyncTransactionButtonProps> = ({ syncRecordId }) => {
    const [acknowledgeSyncEvent] = wmsApi.useAcknowledgeSyncEventMutation()
    const dispatch = useDispatch()

    const handleAcknowledge = () => {
        executeMutation(
            acknowledgeSyncEvent(syncRecordId),
            dispatch,
            'Event acknowledged',
            'Could not acknowledge event'
        )
    }

    return (
        <CButton color={'primary'} onClick={handleAcknowledge}>
            <CIcon className={'mr-1'} icon={cilExternalLink} size="sm" />
            Acknowledge (uten å prosessere)
        </CButton>
    )
}
