import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { getAuthorizedBaseQuery } from '../../common/utils/apiUtils'

export enum WarehouseTransactionType {
    IN = 'IN',
    OUT = 'OUT',
    MOVE = 'MOVE',
    TAKE = 'TAKE',
    PARCEL = 'PARCEL',
    NOT_SUPPORTED = 'NOT_SUPPORTED',
}

export interface WarehouseSyncLine {
    sku: string
    quantity: number
}

export interface WarehouseSyncRecord {
    id: string
    type: WarehouseTransactionType
    lines: WarehouseSyncLine[]
}
export const wmsApi = createApi({
    reducerPath: 'wms',
    baseQuery: getAuthorizedBaseQuery(),
    tagTypes: ['Wms'],
    endpoints: (builder) => ({
        getNextSyncEvent: builder.query<WarehouseSyncRecord, void>({
            query: () => ({
                url: `/admin/wms/events/sync/next`,
                method: 'GET',
            }),
            providesTags: (result) => (result ? [{ type: 'Wms', eventId: result.id }] : []),
        }),
        acknowledgeSyncEvent: builder.mutation<void, string>({
            query: (syncRecordId) => ({
                url: `/admin/wms/events/sync/${syncRecordId}/acknowledge`,
                method: 'POST',
            }),
            invalidatesTags: (_, __, syncRecordId) => [
                {
                    type: 'Wms',
                    eventId: syncRecordId,
                },
            ],
        }),
    }),
})
