import { Page } from '../../common'
import { useParams } from 'react-router'
import { CAlert, CCol, CRow, CSpinner } from '@coreui/react-pro'
import { useGetProductsQuery } from '../store/productApi'
import React from 'react'
import SalesHistoryChart from '../components/SalesHistoryChart'
import ProductInfoCard from '../components/ProductInfoCard'
import ProductPriceCard from '../components/ProductPriceCard'
import ProductOnboardingWizard from '../components/ProductOnboardingWizard'
import SupplierCard from '../components/SupplierCard'
import PublishProductCard from '../components/PublishProductCard'
import ProductDetailsToolbar from '../components/ProductDetailsToolbar'
import ProductInventoryCard from '../components/ProductInventoryCard'
import ProductBarcodeCard from '../components/ProductBarcodeCard'
import ProductDimensionsCard from '../components/ProductDimensionsCard'
import ProductPackingInfoCard from '../components/ProductPackingInfoCard'
import ProductBundleCard from '../components/ProductBundleCard'
import ProductInBundleCard from '../components/ProductInBundleCard'
import { inventoryApi } from '../../inventory/store/inventoryApi'
import B2BB2CProductSettingsCard from '../components/B2BB2CProductSettingsCard'

interface ProductDetailsPageParams {
    productId: string
}

export default function ProductDetailsPage() {
    const { productId } = useParams<ProductDetailsPageParams>()
    const { data: allProducts, isSuccess, isLoading, isError } = useGetProductsQuery()
    const { data: inventory } = inventoryApi.useGetInventoryQuery(productId)
    const product = allProducts?.find((product) => product.id === productId)

    if (isError) {
        return <CAlert color={'danger'}>Kunne ikke hente produkt</CAlert>
    }
    if (isLoading) {
        return <CSpinner />
    }

    const isPartOfBundle = () => {
        return Boolean(product?.containedInBundles?.length)
    }

    const isBundleType = () => {
        return !isPartOfBundle() && inventory?.type === 'BUNDLE'
    }

    return (
        <Page title={product?.title ?? 'Produkt detaljer'} preamble={productId}>
            {isSuccess && product && (
                <>
                    <ProductOnboardingWizard product={product} />
                    <CRow>
                        <CCol md={12}>
                            <ProductDetailsToolbar product={product} />
                        </CCol>
                    </CRow>
                    <CRow>
                        <CCol>
                            <ProductInfoCard product={product} />
                            <SupplierCard supplierId={product.supplierId} />
                        </CCol>
                        <CCol>
                            {isBundleType() && <ProductBundleCard product={product} products={allProducts} />}
                            {isPartOfBundle() && <ProductInBundleCard product={product} products={allProducts} />}
                            <PublishProductCard product={product} />
                            <B2BB2CProductSettingsCard product={product} />
                            <ProductInventoryCard product={product} />
                            <ProductDimensionsCard product={product} />
                            <ProductBarcodeCard product={product} />
                            <ProductPriceCard product={product} />
                            <ProductPackingInfoCard product={product} />
                            <SalesHistoryChart productId={productId} />
                        </CCol>
                    </CRow>
                </>
            )}
        </Page>
    )
}
