import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { getAuthorizedBaseQuery } from '../../common/utils/apiUtils'
import { Address } from '../types'

export const addressApi = createApi({
    reducerPath: 'address',
    baseQuery: getAuthorizedBaseQuery(),
    tagTypes: ['CompanyAddresses'],
    endpoints: (builder) => ({
        getCompanyAddresses: builder.query<Address[], string>({
            query: (companyId) => ({
                url: `/admin/address/company/${companyId}`,
                method: 'GET',
            }),
            providesTags: ['CompanyAddresses'],
        }),
    }),
})

export const { useGetCompanyAddressesQuery } = addressApi
