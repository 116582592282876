import {
    CButton,
    CCol,
    CDatePicker,
    CForm,
    CFormInput,
    CModal,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CModalTitle,
    CMultiSelect,
    CRow,
} from '@coreui/react-pro'
import React from 'react'
import { Article, CreateShipmentRequest, distributionApi } from '../store/distributionApi'
import { productApi } from '../../product/store/productApi'

interface CreateShipmentModalProps {
    visible: boolean
    onClose: () => void
}

export default function CreateShipmentModal({ visible, onClose }: CreateShipmentModalProps) {
    const calendarDate = new Date()
    const [deliveryDate, setDeliveryDate] = React.useState<Date | null>(null)
    const [articles, setArticles] = React.useState<Article[]>([])
    const [createShipment] = distributionApi.useCreateShipmentMutation()
    const { data: products } = productApi.useGetProductsQuery()
    const handleCreateShipment = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        const formData = new FormData(e.currentTarget)
        const jsonData: Record<string, any> = {}
        formData.forEach((value, key) => {
            jsonData[key] = value
        })
        jsonData['deliveryDate'] = deliveryDate
        createShipment(jsonData as CreateShipmentRequest)
    }
    const addArticle = () => {
        setArticles([...articles, { quantity: 1, articleNumber: '', articleName: '' }])
    }

    const updateArticle = (index: number, field: keyof Article, value: string | number) => {
        const updatedArticles = articles.map((article, idx) =>
            idx === index ? { ...article, [field]: value } : article
        )
        setArticles(updatedArticles)
    }
    const removeArticle = (index: number) => {
        const updatedArticles = articles.filter((_, idx) => idx !== index)
        setArticles(updatedArticles)
    }

    return (
        <CModal visible={visible} onClose={onClose} title="Velg type" size={'xl'}>
            <CForm onSubmit={(e) => handleCreateShipment(e)}>
                <CModalHeader closeButton={true}>
                    <CModalTitle>Opprett sending</CModalTitle>
                </CModalHeader>

                <CModalBody>
                    <CDatePicker
                        calendarDate={calendarDate}
                        locale="no-NB"
                        minDate={calendarDate}
                        onDateChange={(date) => setDeliveryDate(date)}
                        label={'Leveringsdato'}
                        placeholder={'Velg dato'}
                    />
                    <CFormInput type="text" name="name" label={'Navn'} />
                    <CFormInput type="text" name="address" label={'Adresse'} />
                    <CFormInput type="text" name="zipCode" label={'Postnummer'} />
                    <CFormInput type="text" name="city" label={'Poststed'} />
                    <CFormInput type="text" name="email" label={'E-post'} />
                    <CFormInput type="text" name="phone" label={'Telefon'} />
                    {articles.map((article, index) => (
                        <CRow key={index}>
                            <CCol>
                                <CFormInput
                                    type="number"
                                    value={article.quantity}
                                    onChange={(e) => updateArticle(index, 'quantity', Number(e.target.value))}
                                    label={`Antall (Article ${index + 1})`}
                                />
                            </CCol>
                            <CCol>
                                <CMultiSelect
                                    options={
                                        products?.map((product) => ({
                                            value: product.sku,
                                            text: product.supplierName + ' - ' + product.title,
                                        })) || []
                                    }
                                    multiple={false}
                                    onChange={(selected) => {
                                        updateArticle(index, 'articleNumber', selected[0].value)
                                        updateArticle(index, 'articleName', selected[0].value)
                                    }}
                                    label={`Artikkel (Article ${index + 1})`}
                                />
                            </CCol>
                            <CCol>
                                <CButton color="danger" onClick={() => removeArticle(index)}>
                                    Fjern
                                </CButton>
                            </CCol>
                        </CRow>
                    ))}
                    <CButton color="secondary" onClick={addArticle}>
                        Legg til artikkel
                    </CButton>
                </CModalBody>
                <CModalFooter>
                    <CButton color="danger">Avbryt</CButton>
                    <CButton type={'submit'}>Opprett sending</CButton>
                </CModalFooter>
            </CForm>
        </CModal>
    )
}
