import { RegisteredCompany } from '../types'
import { CSmartTable } from '@coreui/react-pro'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'

interface CompanyTableProps {
    companies: RegisteredCompany[]
    onSelectChange?: (ids: string[]) => void
}

export default function CompanyTable({ companies, onSelectChange }: CompanyTableProps) {
    const [itemsShown, setItemsShown] = useState(companies.length)
    const history = useHistory()

    return (
        <>
            <p>
                Displaying {itemsShown} out of {companies.length} Companies
            </p>
            <CSmartTable
                selectable={onSelectChange !== undefined}
                clickableRows
                onFilteredItemsChange={(items) => setItemsShown(items.length)}
                onSelectedItemsChange={(items) => {
                    onSelectChange && onSelectChange(items.map((item) => item.id as string))
                }}
                tableProps={{
                    responsive: true,
                    striped: true,
                    hover: true,
                    borderless: true,
                }}
                onRowClick={(item) => {
                    history.push(`/companies/${item.companyId}`)
                }}
                columns={[
                    {
                        key: 'companyId',
                        label: 'Internal company id',
                        _style: { width: '30%' },
                        //filter: customFilter('externalProductId', { fuzzy: false }),
                    },
                    {
                        key: 'companyName',
                        label: 'Company name',
                        _style: { width: '30%' },
                        //filter: customFilter('sku', { fuzzy: false }),
                    },
                    {
                        key: 'companyOrganisationNumber',
                        label: 'Company organisation number',
                        //filter: customFilter('title', { fuzzy: true }),
                    },
                ]}
                itemsPerPage={100000}
                items={companies}
            />
        </>
    )
}
