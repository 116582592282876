import { Route, Switch, useRouteMatch } from 'react-router'
import React from 'react'
import CompanyRegistryListPage from './CompanyRegistryListPage'
import CompanyDetailsPage from './CompanyDetailsPage'

export function CompanyRegistryPage() {
    const { path } = useRouteMatch()
    return (
        <Switch>
            <Route exact path={`${path}/:companyId`}>
                <CompanyDetailsPage />
            </Route>
            <Route exact path={`${path}`}>
                <CompanyRegistryListPage />
            </Route>
        </Switch>
    )
}
